import './App.css';
import React from 'react';
import Counter from './counter';


function App() {

  return (
    <div >
      <Counter/>
    </div>
  );
}

export default App;
