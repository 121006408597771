import './App.css';
import React, { useState } from "react";

function Counter() {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(prevCount => prevCount + 1);
  };

  const handleDecrement = () => {
    setCount(prevCount => prevCount - 1);
  };
  return (
    <div class="wrapper">

      <div class="acontainer">
        <div className="ccontainer">
          <h5 class="bigtext">{count}</h5>
        </div>
        <div className="bcontainer">
          <button onClick={handleDecrement}><h5 class="bigtext">-</h5></button>
          <button onClick={handleIncrement}><h5 class="bigtext">+</h5></button>
          <button onClick={() => setCount(0)}><h5 class="bigtext">C</h5></button>
        </div>
        
      
      </div>



      

      
      
    </div>
  );
}

export default Counter;